<template>
  <div class="flex w-[calc(50%-10px)] mobile:w-full gap-[10px]">
    <p class="min-w-[90px] m-0 text-[16px] leading-[28px] text-dark">{{title}}</p>
    <p
        v-if="subCategory.categoryNo"
        class=" m-0 text-[16px] leading-[28px] font-[Suit-Medium] flex-grow underline cursor-pointer"
        @click="$router.push(`/market/list?sub_category_no=${subCategory.categoryNo}&page=1&order=1`)"
    >{{subCategory.name}}</p>
    <p v-else
        class=" m-0 text-[16px] leading-[28px] font-[Suit-Medium] flex-grow"
    >{{subCategory.name}}</p>
  </div>
</template>
<script>
export default {
  props:{
    title: {
      default: '',
      type: String
    },
    subCategory: {
      default: function(){
        return {
          'name': null,
          'categoryNo': null
        };
      },
      type: Object
    }
  },
}
</script>